import { useDocumentDescription } from '@geoverse/hooks';
import { QueryClientProvider } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import './i18n';
import { AppRoutes } from './routes';
import { queryClient } from './services/query';

function App() {
  const { t } = useTranslation();

  useDocumentDescription(t('common:appDescription'));

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={'/invoice'}>
        <AppRoutes />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
