const FlagUAIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="2.5" width="20" height="15" rx="2" fill="white" />
      <mask
        id="mask0_335_2873"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="20"
        height="16"
      >
        <rect y="2.5" width="20" height="15" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask0_335_2873)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10.5H20V2.5H0V10.5Z"
          fill="#156DD1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 17.5H20V10.5H0V17.5Z"
          fill="#FFD948"
        />
      </g>
    </svg>
  );
};

export { FlagUAIcon };
export default FlagUAIcon;
