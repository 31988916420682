import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Invoice } from '../models';
import { BaseResponse, getInvoice } from '../services';

export const useInvoice = (id: number) => {
  return useQuery<Invoice, AxiosError<BaseResponse>>(
    ['invoice', id],
    () => getInvoice(id),
    { enabled: !!id }
  );
};
