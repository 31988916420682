import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  PayInvoiceConfirmRequestParams,
  PayInvoiceResponseData,
  PayInvoiceResponseError,
  payInvoiceConfirm,
} from '../services';

export const useInvoicePayConfirm = () => {
  return useMutation<
    PayInvoiceResponseData,
    AxiosError<PayInvoiceResponseError>,
    PayInvoiceConfirmRequestParams
  >(
    ['pay'],
    async (params) => {
      const response = await payInvoiceConfirm(params);
      return {
        ...response,
        card_pan: response?.cardPan,
      };
    },
    {}
  );
};
