import { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';

import api from '../api/api';
import * as Types from './invoice-types';

export const getInvoice = async (id: number) => {
  const response: AxiosResponse<Types.InvoiceResponse> = await api.get(
    `/v2/transactions/public?transaction_id=${id}`
  );

  const data = camelCaseKeys(response.data, { deep: true });

  return data;
};

export type BrowserRequestParams = {
  browserAcceptHeader: string;
  browserColorDepth: string;
  browserLanguage: string;
  browserScreenWidth: string;
  browserScreenHeight: string;
  browserWindowWidth: string;
  browserWindowHeight: string;
  browserTimezone: string;
  browserUserAgent: string;
  browserJavascriptEnabled: string;
};

const handler = {
  get(target: never, prop: never) {
    const propsToPatch = ['colorDepth', 'pixelDepth'];
    if (propsToPatch.includes(prop) === false) {
      return target[prop];
    }

    const normal = [48, 32, 24, 16, 15, 8, 4, 2, 1];
    return normal.find((depth) => depth <= target[prop]) || 24;
  },
};

if ('Proxy' in window) {
  window.screen = new Proxy(window.screen, handler);
}

export const payInvoice = async ({
  id,
  cardNumber,
  cardExpireMonth,
  cardExpireYear,
  cardCVV,
  phoneNumber,
}: Types.PayInvoiceRequestParams) => {
  const response: AxiosResponse<Types.PayInvoiceResponse> = await api.post(
    `/v2/transactions/deposit/h2h`,
    {
      id,
      card_pan: cardNumber,
      card_month: cardExpireMonth,
      card_year: cardExpireYear,
      card_cvv: cardCVV,
      user_phone: phoneNumber,
      // browser params
      browser_accept_header: 'application/json',
      browser_color_depth: window.screen.colorDepth,
      browser_language: navigator.language,
      browser_screen_width: window.screen.width,
      browser_screen_height: window.screen.height,
      browser_window_width: window.innerWidth,
      browser_window_height: window.innerHeight,
      browser_timezone: new Date().getTimezoneOffset(),
      browser_user_agent: navigator.userAgent,
      browser_javascript_enabled: 'true',
    }
  );

  const data = camelCaseKeys(response.data, { deep: true });

  return data;
};

export const payInvoiceConfirm = async ({
  id,
}: Types.PayInvoiceConfirmRequestParams) => {
  const response: AxiosResponse<Types.PayInvoiceResponse> = await api.post(
    `/v2/transactions/deposit/h2h/paid/${id}`
  );

  const data = camelCaseKeys(response.data, { deep: true });

  return data;
};
