import axios from 'axios';

const BROWSER_API_URL = `https://${window.location.hostname}/api`;

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? BROWSER_API_URL
      : process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default api;
