import { Button, Dropdown, DropdownPlacement } from '@geoverse/ui';
import { classNames } from '@geoverse/utils';
import { useTranslation } from 'react-i18next';

/* ========================================================================== */
/*                                    Props                                   */
/* ========================================================================== */

export type LanguageDropdownProps = {
  dropdownPlacement?: DropdownPlacement;
};

export const LanguageDropdown = ({
  dropdownPlacement = 'bottomRight',
}: LanguageDropdownProps) => {
  const { i18n } = useTranslation();

  const triggerClasses = classNames(`
    !min-w-0
    !text-white
    !bg-gray-700
    border-transparent
    hover:!bg-gray-800
    focus:!bg-gray-900 
    active:!bg-gray-900 
  `);

  const languages: { title: string; name: string }[] = [
    {
      title: 'English',
      name: 'en',
    },
    {
      title: 'Русский',
      name: 'ru',
    },
    {
      title: 'Українська',
      name: 'ua',
    },
  ];

  return (
    <Dropdown
      placement={dropdownPlacement}
      Trigger={
        <Button size="small" className={triggerClasses}>
          {i18n.language.split('-')[0].toUpperCase()}
        </Button>
      }
    >
      {languages.map((language) => (
        <Dropdown.Item
          key={`language-${language.name}`}
          selected={i18n.language === language.name}
          onClick={() => i18n.changeLanguage(language.name)}
        >
          {language.title}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
