import { Route, Routes } from 'react-router-dom';

import { InvoicePage } from '../pages';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<InvoicePage />} />

      {/* <Route path="/errors/*" element={<ErrorsLayout />}>
        <Route path="server-error" element={<ServerErrorPage />} />
        <Route path="not-found" element={<NotFoundPage />} />
        <Route path="forbidden" element={<ForbiddenPage />} />
        <Route path="maintenance" element={<MaintenancePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route> */}
    </Routes>
  );
};
