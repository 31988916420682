import { ReactComponent as LogoCompany } from './geo-pay-company.svg';
import { ReactComponent as Logo } from './geo-pay.svg';

const GEOPayLogo = ({ ...props }) => {
  return <Logo {...props} />;
};

const GEOPayCompanyLogo = ({ ...props }) => {
  return <LogoCompany {...props} />;
};

export { GEOPayLogo, GEOPayCompanyLogo };
export default GEOPayLogo;
