import React from 'react';

import logoPath from './mastercard.svg';

const MastercardLogo = ({
  ...props
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  return <img src={logoPath} alt="Mastercard logo" {...props} />;
};

export { MastercardLogo };
export default MastercardLogo;
