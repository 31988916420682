import React from 'react';

import logoPath from './visa.svg';

const VisaLogo = ({
  ...props
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  return <img src={logoPath} alt="Visa logo" {...props} />;
};

export { VisaLogo };
export default VisaLogo;
