import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';
import numeral from 'numeral';
import 'numeral/locales/ru';
import 'numeral/locales/uk-ua';
import { initReactI18next } from 'react-i18next';

const supportedLngs = ['en', 'ru', 'ua'];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common', 'invoice'],
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs: supportedLngs,
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
      reloadInterval: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  const formattedLng = lng.split('-')[0];

  if (lng && supportedLngs.includes(formattedLng)) {
    numeral.locale(formattedLng === 'ua' ? 'uk-ua' : formattedLng);
    moment.locale(i18n.language);
  } else {
    numeral.locale('en');
  }
});

export default i18n;
